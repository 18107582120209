import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  reviewsContainer: {
    paddingTop: 100,
    paddingBottom: 40,
    overflow: 'hidden',
    backgroundColor: '#F9FBFD',
    '& ::before': {
      flex: 0,
      padding: 0
    },
    '@media only screen and (max-width: 425px)': {
      paddingTop: 1,
      paddingBottom: 70
    },
    '& blockquote': {
      quotes: 'none'
    }
  },
  title: {
    fontSize: 40,
    fontWeight: 700,
    lineHeight: '50px',
    marginBottom: 30,
    textAlign: 'center',
    color: '#414042',
    '@media only screen and (max-width: 960px)': {
      fontSize: 35,
      lineHeight: '45px'
    },
    '@media only screen and (max-width: 425px)': {
      fontSize: 26,
      lineHeight: '36px',
      marginBottom: 10
    },
    '@media only screen and (max-width: 320px)': {
      fontSize: 24,
      lineHeight: '34px'
    },
    '&.smallTitle': {
      fontSize: 32,
      fontWeight: 400,
      color: '#525252',
      letterSpacing: '0px',
      '@media only screen and (max-width: 520px)': {
        fontSize: 20,
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: '1.6px'
      }
    }
  },
  textSlogan: {
    marginTop: 20,
    fontWeight: 500,
    color: '#414042',
    fontSize: 20,
    lineHeight: '30px',
    textAlign: 'center',
    '@media only screen and (max-width: 425px)': {
      fontSize: 16,
      lineHeight: '26px'
    }
  },
  quote: {
    margin: '12px 0 80px 0',
    fontSize: 18,
    lineHeight: '28px',
    color: '#414042',
    fontWeight: '400',
    textAlign: 'center',
    '& p': {
      marginTop: 10
    },
    '@media only screen and (max-width: 960px)': {
      fontSize: 14,
      lineHeight: '24px',
      marginBottom: 50
    }
  },
  quoteImg: {
    width: 'auto',
    height: 40,
    '&.h-25': {
      height: 25
    },
    '&.h-45': {
      height: 45
    },
    '@media only screen and (max-width: 960px)': {
      height: 30,
      '&.h-25': {
        height: 15
      },
      '&.h-45': {
        height: 35
      }
    }
  },
  quoteTextClipping: {
    background: 'linear-gradient(90deg,#f5d30033 0,#eb7e252e 100%)',
    padding: '0 8px',
    backgroundColor: '#fff',
    '-webkit-box-decoration-break': 'clone',
    boxDecorationBreak: 'clone',
    borderRadius: '8px'
  },
  cite: {
    display: 'flex',
    justifyContent: 'center',
    '& a': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '24px',
      textDecorationColor: '#414042',
      color: '#414042',
      transition: 'all .2s',
      '@media only screen and (max-width: 960px)': {
        fontSize: 12,
        lineHeight: '22px'
      }
    },
    '&:hover': {
      '& a': {
        textDecorationColor: '#0082f9',
        color: '#8656da'
      }
    }
  },
  avatar: {
    width: 40,
    height: 40,
    marginRight: 5,
    fontStyle: 'normal',
    background: '#F5D300',
    '& .avatarFig': {
      position: 'absolute',
      width: '100%',
      height: 12,
      background: '#F5D300',
      bottom: 0
    },
    '&.bg-2': {
      background: '#5BC005',
      '& .avatarFig': {
        background: '#5BC005'
      }
    },
    '&.bg-3': {
      background: '#EB7E25',
      '& .avatarFig': {
        background: '#EB7E25'
      }
    },
    '@media only screen and (max-width: 960px)': {
      width: 30,
      height: 30,
      '& .avatarFig': {
        height: 7
      }
    }
  },
  more: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  button: {
    textDecoration: 'none',
    fontSize: 18,
    lineHeight: '28px',
    fontWeight: 600,
    textTransform: 'capitalize',
    padding: '10px 30px',
    borderRadius: '45px',
    transition: 'all .5',
    width: 175,
    margin: '0 auto',
    '&:hover': {
      backgroundColor: '#f2f2f2'
    },
    backgroundColor: '#E9E9E9',
    color: '#414042',
    '@media only screen and (max-width: 425px)': {
      fontSize: '16px',
      lineHeight: '18px'
    }
  }
}));
